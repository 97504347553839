<template>
  <div class="overall">
    <div class="title">
      <h2>Top List</h2>
    </div>
    <div class="bgf0 advertiser-info-class">
      <el-row type="flex">
        <el-col :span="24" style="padding:16px;">
          <el-card shadow="hover" class="pad30">
            <el-form :inline="true" :model="filter">
              <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                <el-form-item label="Time">
                  <el-date-picker
                    v-model="startToEndDate"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    style="width:260px;"
                    align="center"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                <el-form-item label="Countries" prop="countries">
                  <el-input
                    v-model="filter.countries"
                    placeholder="countries,eg.HK,US,CN.."
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                <el-button type="primary" @click="searchListClick(1)">Search</el-button>
                <el-button type="primary" @click="downloadReport">Download Excel</el-button>
              </el-col>
            </el-form>
          </el-card>
          <el-card shadow="hover">
            <el-table
              v-loading="listLoading"
              border
              stripe
              highlight-current-row
              :data="dataList"
              style="width:100%"
              height="70vmin"
              @cell-click="cellClick"
            >
              <el-table-column label="OfferName" prop="offerName" align="center" fixed="left">
              </el-table-column>
              <el-table-column
                label="Pkg"
                prop="offerProd"
                align="center"
                min-width="110"
                fixed="left"
              >
              </el-table-column>
              <el-table-column
                label="Country"
                prop="country"
                align="center"
                show-overflow-tooltip
                min-width="100"
              ></el-table-column>
              <el-table-column
                label="Price"
                prop="payoutValue"
                align="center"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.payoutValue ? parseFloat(scope.row.payoutValue).toFixed(2) : ''
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Redurects"
                prop="minNum"
                align="center"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.minNum === -1
                      ? scope.row.maxNum !== 0
                        ? scope.row.maxNum
                        : 'direct'
                      : scope.row.minNum === 0
                      ? 'direct'
                      : scope.row.minNum
                  }}</span>
                </template>
              </el-table-column>
            </el-table>
            <pagination
              class="block pagePanel"
              :pageTotal="total"
              @handleSizeChange="handleSizeChange"
              @handleCurrentChange="handleCurrentChange"
              :pageSize="pageSize"
              :currentPage="currentPage"
            >
            </pagination>
          </el-card>
        </el-col>
      </el-row>
      <el-dialog
        title="Top Detail"
        :modal-append-to-body="false"
        :visible.sync="advertiserInfoVisible"
      >
        <div class="advertiser-info-body">
          <p class="row-line-height"
            ><span class="sky-blue">Source Name: </span>{{ advertiserInfoShowData.sourceName }}</p
          >
          <p class="row-line-height"
            ><span class="sky-blue">Source Tracking Link: </span
            >{{ advertiserInfoShowData.trackingLink }}</p
          >
          <p class="row-line-height"
            ><span class="sky-blue">KPI: </span>{{ advertiserInfoShowData.description }}</p
          >
          <p class="row-line-height"
            ><span class="sky-blue">payout: </span>{{ advertiserInfoShowData.payout }}</p
          >
          <p class="row-line-height"
            ><span class="sky-blue">revenue: </span>{{ advertiserInfoShowData.revenue }}</p
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
  import { getTopList, api, getTopOffer } from 'api/topList';
  import { myMixin } from '@/mixins/mixins.js';
  import Pagination from '@/components/pagination';
  import dateUtils from '@/utils/dateutils.js';
  import qs from 'querystring';

  export default {
    name: 'TopList',
    mixins: [myMixin],
    components: {
      Pagination,
    },
    data() {
      return {
        pageSize: 20,
        currentPage: 1,
        filter: {
          fromDate: '',
          toDate: '',
          countries: '',
        },
        listLoading: false,
        total: null,
        closemoda: false,
        dataList: [],
        startToEndDate: [],
        advertiserInfoVisible: false,
        advertiserInfoShowData: {
          sourceName: '',
          trackingLink: '',
          description: '',
        },
        advertiserInfoData: [],
      };
    },
    mounted() {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() + 3600 * 1000 * 24);
      this.startToEndDate = [];
      this.startToEndDate[0] = dateUtils.date2StrYMD(end);
      this.startToEndDate[1] = dateUtils.date2StrYMD(start);
      this.searchListClick();
    },
    methods: {
      searchListClick(curPage) {
        this.listLoading = true;
        if (curPage) {
          this.currentPage = curPage;
        }
        let param = {
          ...this.filter,
          page: this.currentPage,
          pageSize: this.pageSize,
        };
        if (this.startToEndDate) {
          [param.fromDate = '', param.toDate = ''] = this.startToEndDate;
        }
        getTopList(param).then((response) => {
          this.listLoading = false;
          if (response.code === 200) {
            this.dataList = response.result;
            this.total = response.total;
          } else {
            this.$message.error(response.message);
          }
        });
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        this.searchListClick();
      },
      downloadReport() {
        let param = {
          ...this.filter,
          page: this.currentPage,
          pageSize: this.pageSize,
        };
        if (this.startToEndDate) {
          [param.fromDate = '', param.toDate = ''] = this.startToEndDate;
        }

        const strParam = qs.stringify(param);
        window.open(`${api.DOWNLOAD_TOP_LIST}?${strParam}`, '_blank');
      },
      cellClick(row) {
        const that = this;
        let param = {};
        if (this.startToEndDate) {
          [param.fromDate = '', param.toDate = ''] = this.startToEndDate;
        }
        param.country = row.country;
        param.offerProd = row.offerProd;
        const dataIndex = JSON.stringify(param);
        if (this.advertiserInfoData[dataIndex]) {
          this.advertiserInfoShowData = this.advertiserInfoData[dataIndex];
          this.advertiserInfoVisible = true;
          return false;
        }
        getTopOffer(param)
          .then((response) => {
            if (response.code === 200) {
              this.advertiserInfoShowData = response.result;
              this.advertiserInfoData[dataIndex] = response.result;
              this.advertiserInfoVisible = true;
            } else {
              this.$message.error(response.message);
            }
          })
          .catch((error) => {
            that.$message.error(error.toString());
          });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .cor337ab7 {
    color: #337ab7;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  ::v-deep .el-transfer {
    display: flex;
    justify-content: center;
    align-items: center;
    .el-transfer-panel {
      width: 288px !important;
    }
  }
  .advertiser-info-class {
    .advertiser-info-body {
      max-height: 60vh;
      overflow-y: auto;
    }
    .row-line-height {
      padding-bottom: 4px;
    }
    .sky-blue {
      color: var(--header-bg-color);
    }
    ::v-deep .el-dialog__body {
      padding: 1em;
    }
    ::v-deep .el-dialog__header {
      padding: 0.8em 1em 0 1em;
    }
    ::v-deep .v-modal {
      background-color: rgba(0, 0, 0, 0);
    }
  }
</style>
