import request from 'utils/request';

export const api = {
  TOP_LIST: '/api/postback/billing/topList',
  DOWNLOAD_TOP_LIST: '/api/postback/billing/downloadExcel',
  TOP_OFFER: '/api/postback/billing/topOffer',
};

export function getTopList(query) {
  return request({
    url: api.TOP_LIST,
    method: 'get',
    params: query,
  });
}

export function getTopOffer(query) {
  return request({
    url: api.TOP_OFFER,
    method: 'get',
    params: query,
  });
}
